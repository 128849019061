import Image from "next/image";
import * as moment from "node_modules/moment/moment";
import StarRating from "./StarRating";
import firebase from "firebase/clientApp";
import { useState } from "react";
import Link from "next/link";

const Offer = ({ data }) => {
  const { imageUrl, name, endDate, price, hotelData, id, offerExpiration } =
    data;
  const { rating, name: hotelName } = hotelData;

  return (
    <link href="{`/offer/${id}`}">
      <a className="group hover:cursor-pointer">
        <div className="space-y-1 pr-4">
          <div className="w-full h-52 relative rounded-xl overflow-hidden mb-3">
            <image src="{imageUrl}" layout="fill" objectFit="cover" className="w-full transform transition-transform duration-1000 group-hover:scale-150" alt="{name}"></image>
          </div>
          <div className="flex space-x-2 items-center text-xs font-bold uppercase">
            <p className="text-white py-1 px-2 rounded bg-bali">Oferta</p>
            <p className="flex-grow text-bali">
              Rest {moment().to(moment(offerExpiration), true)}
            </p>
            <starrating value="{rating}" color="bali"></starrating>
          </div>
          <p className="font-bold group-hover:underline">{nombre}</p>
          <div className="flex space-x-1 text-sm text-bali font-bold">
            <p>{hotelName}</p>
            <p>-</p>
            <p>{precio}€</p>
          </div>
        </div>
      </a>
    
  );
};

export default Offer;
